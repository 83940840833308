import { ActionContext } from 'vuex';
import resolveLabel from '@/utils/I18nLabel';
import { date2YMDString } from '@/utils/dateHelpers';
import i18n from '@/i18n';
import hasFeature from '@/utils/feature';
import palette from '@/utils/palette';
import { Flavor } from '@/flavor/types';
import { flavor, flavorData } from '@/flavor';
import Log from '@/utils/log';
import { getActiveTransportAggregatedModes, getEmissionAggregatedModes } from '@/utils/maAggregate';
import { RootState } from '../../root-state';
import {
  ResultBox, ResultBoardStateInterface, ResultBoard, ResultVisual, ResultFormatter, FilterOverride, ResultUnit,
} from './types';
import {
  Numerator, Denominator, StatMethod, TravelType, Attachment, PartialQuery, Query, Axis,
} from '../query/types';
import { SampleVariable, SampleClass } from '../sample/types';
import { AggregatedMode } from '../mode-activity/types';

type Context = ActionContext<ResultBoardStateInterface, RootState>;

const noFilterOverride : FilterOverride = {
  aggregatedModes: undefined,
};

export interface ResultBoardLoadParams {
  sampleVariables : Array<SampleVariable>;
  surveyStartDate: string|null;
  surveyEndDate: string|null;
  aggregatedModes: Array<AggregatedMode>;
}

export interface SetVisualParams {
  resultBoardId : string;
  boxId : number;
  type: ResultVisual;
}

export interface SetZoneFileParams {
  resultBoardId : string;
  boxId : number;
  zoneFile: Attachment|null; // null => remove file
}

export interface SetStartEndDateParams {
  startDate : string|null;
  endDate : string|null;
}

/**
 * Lookup box or return undefined if not found
 */
function findBox(state : ResultBoardStateInterface, resultBoardId: String, boxId: number): ResultBox|undefined {
  const resultBoard = state.resultBoards.find((board) => board.id === resultBoardId);
  if (resultBoard !== undefined) {
    return resultBoard.resultBoxes.find((b) => b.id === boxId);
  }
  return undefined;
}

function attachmentState(file : Attachment|null): string {
  if (file === null) {
    return 'null';
  }
  // Naively use filename and content length as state signature
  return `${file.fileName}:${file.content.length}`;
}

/**
 * Resolve dates() axis string with given parameters.
 *
 * If either startDate or endDate is null, a days axis for the last 30 days
 * is returned.
 *
 * @param startDate Date string on YYYY-MM-DD format or null
 * @param endDate Date string on YYYY-MM-DD format or null
 * @returns string
 */
function resolveDatesAxis(startDate : string|null, endDate : string|null): string {
  if (startDate == null || endDate == null) {
    const today = new Date();
    const prior = new Date();
    prior.setDate(today.getDate() - 30);
    const end = date2YMDString(today);
    const start = date2YMDString(prior);
    return `dates(${start},${end})`;
  }
  return `dates(${startDate},${endDate})`;
}

/**
 * Resolve weeks() axis string with given parameters.
 *
 * If either startDate or endDate is null, a weeks axis for the last 30 days
 * is returned.
 *
 * @param startDate Date string on YYYY-MM-DD format or null
 * @param endDate Date string on YYYY-MM-DD format or null
 * @returns string
 */
function resolveWeeksAxis(startDate : string|null, endDate : string|null): string {
  if (startDate == null || endDate == null) {
    const today = new Date();
    const prior = new Date();
    prior.setDate(today.getDate() - 30);
    const end = date2YMDString(today);
    const start = date2YMDString(prior);
    return `weeks(${start},${end})`;
  }
  return `weeks(${startDate},${endDate})`;
}

/**
 * Build a ResultBox of type ResultVisual.sectionHeading.
 * @param id The id to use
 * @param label The label (translated text)
 * @returns ResultBox
 */
function buildSectionHeadingBox(id: number, label: string) : ResultBox {
  return {
    id,
    type: ResultVisual.sectionHeading,
    label,
    partialQuery: null,
    filterOverride: noFilterOverride,
    factTextStr: null,
    valueFormatter: null,
    valueDecimals: 0,
    valueUnit: null,
    customPalette: null,
    colspan: 12,
  };
}

/**
 * (current) User state management
 */
export default {
  namespaced: true,
  state: <ResultBoardStateInterface> {
    resultBoards: [],
  },
  mutations: {
    /**
     * Store a query as sent
     * @param state
     * @param query
     */
    addBoard(state : ResultBoardStateInterface, resultBoard : ResultBoard) {
      state.resultBoards.push(resultBoard);
    },
    clear(state : ResultBoardStateInterface) {
      state.resultBoards = [];
    },
    /**
     * Set visual type of Result Box
     * @param state
     * @param payload
     */
    setBoxVisual(state : ResultBoardStateInterface, payload : SetVisualParams) {
      const box = findBox(state, payload.resultBoardId, payload.boxId);
      if (box !== undefined) {
        box.type = payload.type;
      }
    },
    /**
     * Set partialQuery.zoneFile of Result Box
     * @param state
     * @param payload
     */
    setBoxZoneFile(state : ResultBoardStateInterface, payload : SetZoneFileParams) {
      const box = findBox(state, payload.resultBoardId, payload.boxId);
      Log.log('setBoxZoneFile');
      if (box !== undefined && box.partialQuery !== null) {
        box.partialQuery.zoneFile = payload.zoneFile;
      }
    },
    /* eslint no-param-reassign: ["error", { "props": false }] */
    /**
     * Set Start and end date of all result boxes of axis dates() and weeks().
     *
     * If either the start or end date is null, then today's date is used as end date and the
     * day 30 day prior used as start date
     * @param state
     * @param payload
     */
    setStartEndDate(state : ResultBoardStateInterface, payload : SetStartEndDateParams) {
      state.resultBoards.forEach((resultBoard) => {
        resultBoard.resultBoxes.forEach((box) => {
          if (box.partialQuery !== null) {
            if (box.partialQuery.axisX.startsWith('dates(')) {
              box.partialQuery.axisX = resolveDatesAxis(payload.startDate, payload.endDate);
            }
            if (box.partialQuery.axisX.startsWith('weeks(')) {
              box.partialQuery.axisX = resolveWeeksAxis(payload.startDate, payload.endDate);
            }
          }
        });
      });
    },
  },
  actions: {
    load(context : Context, payload: ResultBoardLoadParams) {
      return new Promise<void>(async (resolve, reject) => {
        context.commit('clear');
        let nextId = 0;
        const getNextId = () => {
          const id = nextId;
          nextId += 1;
          return id;
        };

        const modalSplitNTrips: ResultBox = {
          id: getNextId(),
          type: ResultVisual.pieChart,
          label: i18n.t('resultbox.modal-split-n-trips').toString(),
          partialQuery: {
            axisX: '"aggregated mode"',
            axisY: '',
            numerator: Numerator.nDelresa,
            denominator: Denominator.anyDayDivN,
            scaleFactor: null,
            statMethod: StatMethod.estimatingProportion,
            travelType: TravelType.delresa,
            enableWeighting: true,
            zoneFile: null,
            shareOutput: false,
            calculateMapFilter: false,
          },
          filterOverride: noFilterOverride,
          factTextStr: null,
          valueFormatter: null,
          valueDecimals: 1,
          valueUnit: null,
          customPalette: null,
          colspan: 6,
        };

        // Compensate for that people working full time are working 5 out of 7 days.
        const workFullTimeFactor = 7 / 5;

        if (flavor === Flavor.travelViewer) {
          // Build result boards for travelViewer
          const overviewBoard : ResultBoard = {
            id: 'overview',
            labelStr: 'resultboard.overview',
            info: null,
            resultBoxes: [
              // modalSplitNTrips, // commented out to avoid slow graph on overview during development
              // N Trips per person and day
              {
                id: getNextId(),
                type: ResultVisual.factText,
                label: i18n.t('resultbox.n-trips-per-person-day').toString(),
                partialQuery: {
                  axisX: '',
                  axisY: '',
                  numerator: Numerator.nDelresa,
                  denominator: Denominator.anyDayDivN,
                  scaleFactor: null,
                  statMethod: StatMethod.estimatingProportion,
                  travelType: TravelType.delresa,
                  enableWeighting: true,
                  zoneFile: null,
                  shareOutput: false,
                  calculateMapFilter: false,
                },
                filterOverride: noFilterOverride,
                factTextStr: 'resultbox.fact-text.n-trips-per-person-day',
                valueFormatter: null,
                valueDecimals: 1,
                valueUnit: null,
                customPalette: null,
                colspan: 4,
              },
              // Distance per person and day
              {
                id: getNextId(),
                type: ResultVisual.factText,
                label: i18n.t('resultbox.distance-per-person-day').toString(),
                partialQuery: {
                  axisX: '',
                  axisY: '',
                  numerator: Numerator.delresaDistanceM,
                  denominator: Denominator.anyDayDivN,
                  scaleFactor: null,
                  statMethod: StatMethod.estimatingProportion,
                  travelType: TravelType.delresa,
                  enableWeighting: true,
                  zoneFile: null,
                  shareOutput: false,
                  calculateMapFilter: false,
                },
                filterOverride: noFilterOverride,
                factTextStr: 'resultbox.fact-text.distance-per-person-day',
                valueFormatter: ResultFormatter.mToKm,
                valueDecimals: 0,
                valueUnit: ResultUnit.km,
                customPalette: null,
                colspan: 4,
              },
              // Distance per person and day
              {
                id: getNextId(),
                type: ResultVisual.factText,
                label: i18n.t('resultbox.duration-per-person-day').toString(),
                partialQuery: {
                  axisX: '',
                  axisY: '',
                  numerator: Numerator.delresaDurationS,
                  denominator: Denominator.anyDayDivN,
                  scaleFactor: null,
                  statMethod: StatMethod.estimatingProportion,
                  travelType: TravelType.delresa,
                  enableWeighting: true,
                  zoneFile: null,
                  shareOutput: false,
                  calculateMapFilter: false,
                },
                filterOverride: noFilterOverride,
                factTextStr: 'resultbox.fact-text.duration-per-person-day',
                valueFormatter: ResultFormatter.sToMinutes,
                valueDecimals: 0,
                valueUnit: ResultUnit.minutes,
                customPalette: null,
                colspan: 4,
              },
            ],
          };
          context.commit('addBoard', overviewBoard);
          const tripsBoard : ResultBoard = {
            id: 'trips',
            labelStr: 'resultboard.trips',
            info: null,
            resultBoxes: [
              modalSplitNTrips,
              // Modal split
              {
                id: getNextId(),
                type: ResultVisual.pieChart,
                label: i18n.t('resultbox.modal-split-trip-distance').toString(),
                partialQuery: {
                  axisX: '"aggregated mode"',
                  axisY: '',
                  numerator: Numerator.delresaDistanceM,
                  denominator: Denominator.anyDayDivN,
                  scaleFactor: null,
                  statMethod: StatMethod.estimatingProportion,
                  travelType: TravelType.delresa,
                  enableWeighting: true,
                  zoneFile: null,
                  shareOutput: false,
                  calculateMapFilter: false,
                },
                filterOverride: noFilterOverride,
                factTextStr: null,
                valueFormatter: ResultFormatter.mToKm,
                valueDecimals: 0,
                valueUnit: ResultUnit.km,
                customPalette: null,
                colspan: 6,
              },
              {
                id: getNextId(),
                type: ResultVisual.pieChart,
                label: i18n.t('resultbox.modal-split-trip-duration').toString(),
                partialQuery: {
                  axisX: '"aggregated mode"',
                  axisY: '',
                  numerator: Numerator.delresaDurationS,
                  denominator: Denominator.anyDayDivN,
                  scaleFactor: null,
                  statMethod: StatMethod.estimatingProportion,
                  travelType: TravelType.delresa,
                  enableWeighting: true,
                  zoneFile: null,
                  shareOutput: false,
                  calculateMapFilter: false,
                },
                filterOverride: noFilterOverride,
                factTextStr: null,
                valueFormatter: ResultFormatter.sToMinutes,
                valueDecimals: 0,
                valueUnit: ResultUnit.minutes,
                customPalette: null,
                colspan: 6,
              },
              {
                id: getNextId(),
                type: ResultVisual.barChart,
                label: i18n.t('resultbox.n-trips-per-week-day').toString(),
                partialQuery: {
                  axisX: '"week days"',
                  axisY: '',
                  numerator: Numerator.nDelresa,
                  denominator: Denominator.anyDayDivN,
                  scaleFactor: null,
                  statMethod: StatMethod.estimatingProportion,
                  travelType: TravelType.delresa,
                  enableWeighting: true,
                  zoneFile: null,
                  shareOutput: false,
                  calculateMapFilter: false,
                },
                filterOverride: noFilterOverride,
                factTextStr: null,
                valueFormatter: null,
                valueDecimals: 1,
                valueUnit: null,
                customPalette: null,
                colspan: 6,
              },
              // Activity split
              {
                id: getNextId(),
                type: ResultVisual.pieChart,
                label: i18n.t('resultbox.activity-split-n-trips').toString(),
                partialQuery: {
                  axisX: '"aggregated activity"',
                  // axisX: '',
                  axisY: '',
                  numerator: Numerator.nDelresa,
                  denominator: Denominator.anyDayDivN,
                  scaleFactor: null,
                  statMethod: StatMethod.estimatingProportion,
                  travelType: TravelType.delresa,
                  enableWeighting: true,
                  zoneFile: null,
                  shareOutput: false,
                  calculateMapFilter: false,
                },
                filterOverride: noFilterOverride,
                factTextStr: null,
                valueFormatter: null,
                valueDecimals: 1,
                valueUnit: null,
                customPalette: null,
                colspan: 6,
              },
              {
                id: getNextId(),
                type: ResultVisual.pieChart,
                label: i18n.t('resultbox.activity-split-trip-distance').toString(),
                partialQuery: {
                  axisX: '"aggregated activity"',
                  axisY: '',
                  numerator: Numerator.delresaDistanceM,
                  denominator: Denominator.anyDayDivN,
                  scaleFactor: null,
                  statMethod: StatMethod.estimatingProportion,
                  travelType: TravelType.delresa,
                  enableWeighting: true,
                  zoneFile: null,
                  shareOutput: false,
                  calculateMapFilter: false,
                },
                filterOverride: noFilterOverride,
                factTextStr: null,
                valueFormatter: ResultFormatter.mToKm,
                valueDecimals: 0,
                valueUnit: ResultUnit.km,
                customPalette: null,
                colspan: 6,
              },
              {
                id: getNextId(),
                type: ResultVisual.pieChart,
                label: i18n.t('resultbox.activity-split-trip-duration').toString(),
                partialQuery: {
                  axisX: '"aggregated activity"',
                  axisY: '',
                  numerator: Numerator.delresaDurationS,
                  denominator: Denominator.anyDayDivN,
                  scaleFactor: null,
                  statMethod: StatMethod.estimatingProportion,
                  travelType: TravelType.delresa,
                  enableWeighting: true,
                  zoneFile: null,
                  shareOutput: false,
                  calculateMapFilter: false,
                },
                filterOverride: noFilterOverride,
                factTextStr: null,
                valueFormatter: ResultFormatter.sToMinutes,
                valueDecimals: 0,
                valueUnit: ResultUnit.minutes,
                customPalette: null,
                colspan: 6,
              },
              // average Distance per trip
              {
                id: getNextId(),
                type: ResultVisual.barChart,
                label: i18n.t('resultbox.distance-per-trip').toString(),
                partialQuery: {
                  axisX: '"aggregated mode"',
                  axisY: '',
                  numerator: Numerator.delresaDistanceM,
                  denominator: Denominator.nDelresaWithDistanceDivN,
                  scaleFactor: null,
                  statMethod: StatMethod.weightedTrips,
                  travelType: TravelType.delresa,
                  enableWeighting: true,
                  zoneFile: null,
                  shareOutput: false,
                  calculateMapFilter: false,
                },
                filterOverride: noFilterOverride,
                factTextStr: null,
                valueFormatter: ResultFormatter.mToKm,
                valueDecimals: 0,
                valueUnit: ResultUnit.km,
                customPalette: null,
                colspan: 6,
              },
              // average Duration per trip
              {
                id: getNextId(),
                type: ResultVisual.barChart,
                label: i18n.t('resultbox.duration-per-trip').toString(),
                partialQuery: {
                  axisX: '"aggregated mode"',
                  axisY: '',
                  numerator: Numerator.delresaDurationS,
                  denominator: Denominator.nDelresaWithDurationDivN,
                  scaleFactor: null,
                  statMethod: StatMethod.weightedTrips,
                  travelType: TravelType.delresa,
                  enableWeighting: true,
                  zoneFile: null,
                  shareOutput: false,
                  calculateMapFilter: false,
                },
                filterOverride: noFilterOverride,
                factTextStr: null,
                valueFormatter: ResultFormatter.sToMinutes,
                valueDecimals: 0,
                valueUnit: ResultUnit.minutes,
                customPalette: null,
                colspan: 6,
              },
              // OD matrix
              {
                id: getNextId(),
                type: ResultVisual.odMatrix,
                label: i18n.t('resultbox.od-matrix').toString(),
                partialQuery: {
                  axisX: '"to zone"',
                  axisY: '"from zone"',
                  numerator: Numerator.nDelresa,
                  denominator: Denominator.anyDayDivN,
                  scaleFactor: null,
                  statMethod: StatMethod.estimatingProportion,
                  travelType: TravelType.delresa,
                  enableWeighting: true,
                  zoneFile: null,
                  shareOutput: true,
                  calculateMapFilter: false,
                },
                filterOverride: noFilterOverride,
                factTextStr: null,
                valueFormatter: ResultFormatter.decToPercent,
                valueDecimals: 0,
                valueUnit: null,
                customPalette: null,
                colspan: 12,
              },
            ],
          };
          if (hasFeature('tripsPerSampleClass')) {
            payload.sampleVariables.forEach((sv : SampleVariable) => {
              if (sv.isShare) { // Ignore total row
                tripsBoard.resultBoxes.push({
                  id: getNextId(),
                  type: ResultVisual.barChart,
                  label: i18n.t('resultbox.n-trips-per-sample-variable', {
                    sampleVariable: resolveLabel(sv.label, sv.labelTranslations),
                  }).toString(),
                  partialQuery: {
                    axisX: `sampleVariable(${sv.id})`,
                    axisY: '',
                    numerator: Numerator.nDelresa,
                    denominator: Denominator.anyDayDivN,
                    scaleFactor: null,
                    statMethod: StatMethod.estimatingProportion,
                    travelType: TravelType.delresa,
                    enableWeighting: true,
                    zoneFile: null,
                    shareOutput: false,
                    calculateMapFilter: false,
                  },
                  filterOverride: noFilterOverride,
                  factTextStr: null,
                  valueFormatter: null,
                  valueDecimals: 1,
                  valueUnit: null,
                  customPalette: null,
                  colspan: 6,
                });
              }
            });
          }
          context.commit('addBoard', tripsBoard);
        } else if (flavor === Flavor.activeViewer) {
          // Build result boards for ActiveViewer
          const tripsBoard : ResultBoard = {
            id: 'trips',
            labelStr: 'resultboard.trips',
            info: null,
            resultBoxes: [
              buildSectionHeadingBox(getNextId(), i18n.t('resultboard.section.commuter-trips').toString()),
              {
                id: getNextId(),
                type: ResultVisual.horizontalBarChart,
                label: i18n.tc('resultbox.commuter-travel-days-per-person-day'),
                partialQuery: {
                  axisX: '',
                  axisY: '"before/during/after"',
                  numerator: Numerator.nReselementDays,
                  denominator: Denominator.anyDayDivN,
                  scaleFactor: workFullTimeFactor,
                  statMethod: StatMethod.estimatingProportion, // ?
                  travelType: TravelType.reselement,
                  enableWeighting: false,
                  zoneFile: null,
                  shareOutput: false,
                  calculateMapFilter: false,
                },
                filterOverride: noFilterOverride,
                factTextStr: null,
                valueFormatter: ResultFormatter.decToPercent,
                valueDecimals: 0,
                valueUnit: null,
                customPalette: null,
                colspan: 12,
              },
              {
                id: getNextId(),
                type: ResultVisual.horizontalBarChart,
                label: i18n.tc('resultbox.modal-split-commuter-trips-n-trips'),
                partialQuery: {
                  axisX: '"aggregated mode"',
                  axisY: '"before/during/after"',
                  numerator: Numerator.nHuvudresa,
                  denominator: Denominator.anyDayDivN,
                  scaleFactor: 2 * workFullTimeFactor,
                  statMethod: StatMethod.estimatingProportion,
                  travelType: TravelType.huvudresa,
                  enableWeighting: false,
                  zoneFile: null,
                  shareOutput: Axis.x,
                  calculateMapFilter: false,
                },
                filterOverride: noFilterOverride,
                factTextStr: null,
                valueFormatter: ResultFormatter.decToPercent,
                valueDecimals: 0,
                valueUnit: null,
                customPalette: null,
                colspan: 12,
              },
              {
                id: getNextId(),
                type: ResultVisual.horizontalBarChart,
                label: i18n.tc('resultbox.modal-split-commuter-trips-distance'),
                partialQuery: {
                  axisX: '"aggregated mode"',
                  axisY: '"before/during/after"',
                  numerator: Numerator.reselementDistanceM,
                  denominator: Denominator.anyDayDivN,
                  scaleFactor: 2 * workFullTimeFactor,
                  statMethod: StatMethod.estimatingProportion,
                  travelType: TravelType.reselement,
                  enableWeighting: false,
                  zoneFile: null,
                  shareOutput: false,
                  calculateMapFilter: false,
                },
                filterOverride: noFilterOverride,
                factTextStr: null,
                valueFormatter: ResultFormatter.mToKm,
                valueDecimals: 1,
                valueUnit: ResultUnit.km,
                customPalette: null,
                colspan: 12,
              },
              {
                id: getNextId(),
                type: ResultVisual.horizontalBarChart,
                label: i18n.tc('resultbox.modal-split-commuter-trips-duration'),
                partialQuery: {
                  axisX: '"aggregated mode"',
                  axisY: '"before/during/after"',
                  numerator: Numerator.reselementDurationS,
                  denominator: Denominator.anyDayDivN,
                  scaleFactor: 2 * workFullTimeFactor,
                  statMethod: StatMethod.estimatingProportion,
                  travelType: TravelType.reselement,
                  enableWeighting: false,
                  zoneFile: null,
                  shareOutput: false,
                  calculateMapFilter: false,
                },
                filterOverride: noFilterOverride,
                factTextStr: null,
                valueFormatter: ResultFormatter.sToMinutes,
                valueDecimals: 1,
                valueUnit: ResultUnit.minutes,
                customPalette: null,
                colspan: 12,
              },
            ],
          };
          context.commit('addBoard', tripsBoard);

          const healthBoard : ResultBoard = {
            id: 'health',
            labelStr: 'resultboard.health',
            info: {
              image: 'dumbbell',
              imageLabel: i18n.tc('resultboard.health'),
              palette: '5',
              label: i18n.tc('resultboard.health.board-info.title'),
              textCol1: i18n.tc('resultboard.health.board-info.col1.text'),
              textCol2: i18n.tc('resultboard.health.board-info.col2.text'),
            },
            resultBoxes: [
              buildSectionHeadingBox(getNextId(), i18n.t('resultboard.section.commuter-trips').toString()),
              {
                id: getNextId(),
                type: ResultVisual.horizontalBarChart,
                label: i18n.t('resultbox.commuter-minutes-per-person-and-week').toString(),
                partialQuery: {
                  axisX: '"aggregated mode"',
                  axisY: '"before/during/after"',
                  axisXFilter: {
                    includeIds: getActiveTransportAggregatedModes(payload.aggregatedModes),
                  },
                  numerator: Numerator.reselementDurationS,
                  denominator: Denominator.anyDayDivN,
                  scaleFactor: 2 * 7,
                  statMethod: StatMethod.estimatingProportion,
                  travelType: TravelType.reselement,
                  enableWeighting: false,
                  zoneFile: null,
                  shareOutput: false,
                  calculateMapFilter: false,
                },
                filterOverride: noFilterOverride,
                factTextStr: null,
                valueFormatter: ResultFormatter.sToMinutes,
                valueDecimals: 0,
                valueUnit: ResultUnit.minutes,
                customPalette: [
                  {
                    bgColor: palette['5'],
                    textColor: palette['5d'],
                  },
                  {
                    bgColor: palette['5c'],
                    textColor: palette['5b'],
                  },
                ],
                colspan: 12,
              },

            ],
          };
          context.commit('addBoard', healthBoard);

          // CO2 BOARD
          const CO2Board : ResultBoard = {
            id: 'CO2',
            labelStr: 'resultboard.CO2',
            info: {
              image: 'tree',
              imageLabel: i18n.tc('resultboard.CO2'),
              palette: '4',
              label: i18n.tc('resultboard.CO2.board-info.title'),
              textCol1: i18n.tc('resultboard.CO2.board-info.col1.text'),
              textCol2: i18n.tc('resultboard.CO2.board-info.col2.text'),
            },
            resultBoxes: [
              buildSectionHeadingBox(getNextId(), i18n.t('resultboard.section.commuter-trips').toString()),
              {
                id: getNextId(),
                type: ResultVisual.horizontalBarChart,
                label: i18n.tc('resultbox.emissions-co2-per-participant-and-day'),
                partialQuery: {
                  axisX: '"aggregated mode"',
                  axisY: '"before/during/after"',
                  axisXFilter: {
                    includeIds: getEmissionAggregatedModes(payload.aggregatedModes),
                  },
                  numerator: Numerator.reselementCo2Kg,
                  denominator: Denominator.anyDayDivN,
                  scaleFactor: 2,
                  statMethod: StatMethod.estimatingProportion,
                  travelType: TravelType.reselement,
                  enableWeighting: false,
                  zoneFile: null,
                  shareOutput: false,
                  calculateMapFilter: false,
                },
                filterOverride: noFilterOverride,
                factTextStr: null,
                valueFormatter: null,
                valueDecimals: 2,
                valueUnit: ResultUnit.kg,
                customPalette: [
                  {
                    bgColor: palette['4b'],
                    textColor: palette['4d'],
                  },
                  {
                    bgColor: palette['4c'],
                    textColor: palette['4b'],
                  },
                  {
                    bgColor: palette['4'],
                    textColor: palette['4b'],
                  },
                ],
                colspan: 12,
              },
            ],
          };
          context.commit('addBoard', CO2Board);

          const ParkingBoard : ResultBoard = {
            id: 'Parking',
            labelStr: 'resultboard.parking',
            info: {
              image: 'parking',
              imageLabel: null,
              palette: '1',
              label: i18n.tc('resultboard.parking.board-info.title'),
              textCol1: i18n.tc('resultboard.parking.board-info.col1.text'),
              textCol2: i18n.tc('resultboard.parking.board-info.col2.text'),
            },
            resultBoxes: [
              buildSectionHeadingBox(getNextId(), i18n.t('resultboard.section.commuter-trips').toString()),
              {
                id: getNextId(),
                type: ResultVisual.horizontalBarChart,
                label: i18n.tc('resultbox.car-parking-demand-per-participant'),
                partialQuery: {
                  axisX: '',
                  axisY: '"before/during/after"',
                  numerator: Numerator.nHuvudresaCar,
                  denominator: Denominator.nHuvudresaDivN,
                  scaleFactor: null,
                  statMethod: StatMethod.estimatingProportion,
                  travelType: TravelType.huvudresa,
                  enableWeighting: false,
                  zoneFile: null,
                  shareOutput: false,
                  calculateMapFilter: false,
                },
                filterOverride: noFilterOverride,
                factTextStr: null,
                valueFormatter: ResultFormatter.decToPercent,
                valueDecimals: 1,
                valueUnit: null,
                customPalette: [
                  {
                    bgColor: palette['1'],
                    textColor: palette['1d'],
                  },
                ],
                colspan: 6,
              },
              {
                id: getNextId(),
                type: ResultVisual.horizontalBarChart,
                label: i18n.tc('resultbox.bike-parking-demand-per-participant'),
                partialQuery: {
                  axisX: '',
                  axisY: '"before/during/after"',
                  numerator: Numerator.nHuvudresaBike,
                  denominator: Denominator.nHuvudresaDivN,
                  scaleFactor: null,
                  statMethod: StatMethod.estimatingProportion,
                  travelType: TravelType.huvudresa,
                  enableWeighting: false,
                  zoneFile: null,
                  shareOutput: false,
                  calculateMapFilter: false,
                },
                filterOverride: noFilterOverride,
                factTextStr: null,
                valueFormatter: ResultFormatter.decToPercent,
                valueDecimals: 1,
                valueUnit: null,
                customPalette: [
                  {
                    bgColor: palette['1'],
                    textColor: palette['1d'],
                  },
                ],
                colspan: 6,
              },
            ],
          };
          context.commit('addBoard', ParkingBoard);
        } else {
          Log.error('Invalid flavor');
        }

        // Questionnaire - using sample variables
        // TODO: exclude home area sample variables. (put them in a separate result board)
        const questionnaireBoard : ResultBoard = {
          id: 'questionnaire',
          labelStr: 'resultboard.questionnaire',
          info: null,
          resultBoxes: [
          ],
        };
        payload.sampleVariables.forEach((sv : SampleVariable) => {
          if (sv.isShare) { // Ignore total row
            questionnaireBoard.resultBoxes.push({
              id: getNextId(),
              type: sv.multiMembership ? ResultVisual.barChart : ResultVisual.pieChart,
              label: resolveLabel(sv.label, sv.labelTranslations).toString(),
              partialQuery: {
                axisX: `sampleVariable(${sv.id})`,
                axisY: '',
                numerator: Numerator.nAxisX,
                denominator: Denominator.n,
                scaleFactor: null,
                statMethod: StatMethod.estimatingProportion,
                travelType: flavor === Flavor.travelViewer
                  ? TravelType.delresa
                  : TravelType.reselement,
                enableWeighting: flavor === Flavor.travelViewer,
                zoneFile: null,
                shareOutput: false,
                calculateMapFilter: false,
              },
              filterOverride: noFilterOverride,
              factTextStr: null,
              valueFormatter: ResultFormatter.decToPercent,
              valueDecimals: 0,
              valueUnit: null,
              customPalette: null,
              colspan: 6,
            });
          }
        });
        context.commit('addBoard', questionnaireBoard);

        if (flavor === Flavor.travelViewer) {
          const metaBoard : ResultBoard = {
            id: 'meta',
            labelStr: 'resultboard.meta',
            info: null,
            resultBoxes: [
              // No-travel days per person and day
              {
                id: getNextId(),
                type: ResultVisual.factText,
                label: i18n.t('resultbox.travel-days-per-person-day').toString(),
                partialQuery: {
                  axisX: '',
                  axisY: '',
                  numerator: Numerator.nDelresaDays,
                  denominator: Denominator.anyDayDivN,
                  scaleFactor: null,
                  statMethod: StatMethod.estimatingProportion, // ?
                  travelType: TravelType.delresa,
                  enableWeighting: true,
                  zoneFile: null,
                  shareOutput: false,
                  calculateMapFilter: false,
                },
                filterOverride: noFilterOverride,
                factTextStr: 'resultbox.fact-text.travel-days-per-person-day',
                valueFormatter: ResultFormatter.decToPercent,
                valueDecimals: 0,
                valueUnit: null,
                customPalette: null,
                colspan: 6,
              },
              /*
              // Number of corrected days in total
              {
                id: getNextId(),
                type: ResultVisual.factText,
                label: i18n.t('resultbox.number-of-corrected-days-per-weekday').toString(),
                partialQuery: {
                  axisX: '',
                  axisY: '',
                  numerator: Numerator.nCorrectedDay,
                  denominator: Denominator.one,
                  scaleFactor: null,
                  statMethod: StatMethod.estimatingMean,
                  travelType: TravelType.delresa,
                  enableWeighting: false,
                },
                filterOverride: noFilterOverride,
                factTextStr: 'resultbox.fact-text.travel-days-per-person-day',
                valueFormatter: null,
                valueDecimals: 0,
                valueUnit: null,
                customPalette: null,
                colspan: 6,
              },
              */
              // Number of corrected days per weekday
              {
                id: getNextId(),
                type: ResultVisual.barChart,
                label: i18n.t('resultbox.number-of-corrected-days-per-weekday').toString(),
                partialQuery: {
                  axisX: '"week days"',
                  axisY: '',
                  numerator: Numerator.nCorrectedDay,
                  denominator: Denominator.one,
                  scaleFactor: null,
                  statMethod: StatMethod.estimatingMean,
                  travelType: TravelType.delresa,
                  enableWeighting: false,
                  zoneFile: null,
                  shareOutput: false,
                  calculateMapFilter: false,
                },
                filterOverride: noFilterOverride,
                factTextStr: null,
                valueFormatter: null,
                valueDecimals: 0,
                valueUnit: null,
                customPalette: null,
                colspan: 6,
              },
              {
                id: getNextId(),
                type: ResultVisual.barChart,
                label: i18n.t('resultbox.number-of-corrected-days-per-date').toString(),
                partialQuery: {
                  axisX: resolveDatesAxis(payload.surveyStartDate, payload.surveyEndDate),
                  axisY: '',
                  numerator: Numerator.nCorrectedDay,
                  denominator: Denominator.one,
                  scaleFactor: null,
                  statMethod: StatMethod.estimatingMean,
                  travelType: TravelType.delresa,
                  enableWeighting: false,
                  zoneFile: null,
                  shareOutput: false,
                  calculateMapFilter: false,
                },
                filterOverride: noFilterOverride,
                factTextStr: null,
                valueFormatter: null,
                valueDecimals: 0,
                valueUnit: null,
                customPalette: null,
                colspan: 6,
              },
              {
                id: getNextId(),
                type: ResultVisual.barChart,
                label: i18n.t('resultbox.number-of-corrected-days-per-week').toString(),
                partialQuery: {
                  axisX: resolveWeeksAxis(payload.surveyStartDate, payload.surveyEndDate),
                  axisY: '',
                  numerator: Numerator.nCorrectedDay,
                  denominator: Denominator.one,
                  scaleFactor: null,
                  statMethod: StatMethod.estimatingMean,
                  travelType: TravelType.delresa,
                  enableWeighting: false,
                  zoneFile: null,
                  shareOutput: false,
                  calculateMapFilter: false,
                },
                filterOverride: noFilterOverride,
                factTextStr: null,
                valueFormatter: null,
                valueDecimals: 0,
                valueUnit: null,
                customPalette: null,
                colspan: 6,
              },
            ],
          };
          context.commit('addBoard', metaBoard);
        }
        resolve();
      });
    },
  },
  getters: {
    /**
     * Provides a map from '<board-id>:<box-id>' to a string that
     * represent the current box state of a result box. It is
     * meant only for reacting on changes. Not to be parsed.
     */
    boxState: (state:ResultBoardStateInterface) => {
      const map : any = {};
      state.resultBoards.forEach((board) => {
        board.resultBoxes.forEach((box) => {
          const key = `${board.id}:${box.id}`;
          let s = '';
          s += `t:${box.type}`;
          if (box.partialQuery !== null) {
            const i = attachmentState(box.partialQuery.zoneFile);
            s += `x:${box.partialQuery.axisX}`;
            s += `y:${box.partialQuery.axisY}`;
            s += `n:${box.partialQuery.numerator}`;
            s += `d:${box.partialQuery.denominator}`;
            s += `s:${box.partialQuery.scaleFactor}`;
            s += `w:${box.partialQuery.enableWeighting}`;
            s += `s:${box.partialQuery.statMethod}`;
            s += `t:${box.partialQuery.travelType}`;
            s += `z:${box.partialQuery.zoneFile}`;
            s += `i:${i}`;
            s += `s:${box.partialQuery.shareOutput}`;
          }
          map[key] = s;
        });
      });
      return map;
    },
  },
};
