import axios, { AxiosPromise, AxiosResponse, AxiosRequestConfig } from 'axios';
import { User } from '@/store/modules/user/types';
import { Survey } from '@/store/modules/survey/types';
import { Questionnaire } from '@/store/modules/questionnaire/types';
import { apiUrl } from '@/env';
import modeApi from './mode';
import activityApi from './activity';
import sampleApi from './sample';
import queryApi from './query';
import { authHeaders } from './auth_utils';

export const foo = 'bar';
export const api = {
  async login(username: string, password: string) {
    const params = new URLSearchParams({
      username,
      password,
    });
    return axios.post(`${apiUrl}/v1/login`, params);
  },
  async getMe(token: string) {
    return axios.get<User>(`${apiUrl}/v1/users/me`, authHeaders(token));
  },
  async getSurveyList(token: string) : Promise<AxiosResponse<Array<Survey>>> {
    return axios.get<Array<Survey>>(`${apiUrl}/v1/surveys/list`, authHeaders(token));
  },
  async getSurveyDetails(surveyId : number, token: string) : Promise<AxiosResponse<Survey>> {
    const config : AxiosRequestConfig = authHeaders(token);
    config.params = { surveyId };
    return axios.get<Survey>(`${apiUrl}/v1/surveys/details`, config);
  },
  async getQuestionnaires(surveyId : number, token: string) : Promise<AxiosResponse<Array<Questionnaire>>> {
    const config : AxiosRequestConfig = authHeaders(token);
    config.params = { surveyId };
    return axios.get<Array<Questionnaire>>(`${apiUrl}/v1/questionnaires/list/`, config);
  },
  ...modeApi,
  ...activityApi,
  ...sampleApi,
  ...queryApi,
};
