import { ActionContext } from 'vuex';
import resolveLabel from '@/utils/I18nLabel';
import { api } from '@/api';
import { loadOfflineSampleVariables } from '@/store/offline-data/load';
import timeout from '@/utils/timeout';
import { SampleClass, SampleVariable, SampleStateInterface } from './types';
import { RootState } from '../../root-state';

function getVariableExpandedStorageKey(variable : SampleVariable) : string {
  return `sample.variable[${variable.id}].isExpanded`;
}

export interface SetTargetPopulationParams {
  variableId: number;
  classId: number;
  targetPopulation: number|null;
}

export default {
  namespaced: true,
  state: <SampleStateInterface> {
    variables: [],
    loadError: false,
  },
  mutations: {
    clear(state:SampleStateInterface) {
      state.variables = [];
      state.loadError = false;
    },
    // Private. Use load, setWeightOn, setTargetPopulation action
    setVariables(state:SampleStateInterface, rows : Array<SampleVariable>) {
      state.variables = rows;
    },
    // Private. Use load
    setLoadError(state:SampleStateInterface, loadError : boolean) {
      state.loadError = loadError;
    },
    setIsExpanded(state:SampleStateInterface,
      payload: {variableId : number, isExpanded : boolean}) {
      // ---
      const row = state.variables.find((r : SampleVariable) => r.id === payload.variableId);
      if (row !== undefined) {
        row.isExpanded = payload.isExpanded;
        // Persist in localStorage too
        const storeKey = getVariableExpandedStorageKey(row);
        window.localStorage.setItem(storeKey, JSON.stringify(payload.isExpanded !== false));
      }
    },
  },
  actions: {
    setWeightOn(context:ActionContext<SampleStateInterface, RootState>,
      payload: {variableId : number, weightOn : boolean}) {
      return new Promise<void>(async (resolve, reject) => {
        // ---
        const variables = [...context.state.variables];
        const row = variables.find((r : SampleVariable) => r.id === payload.variableId);
        if (row !== undefined) {
          const oldValue = row.weightOn;
          row.weightOn = payload.weightOn;
          context.commit('setVariables', variables);
          // When there is no server => assign random weights
          if (process.env.VUE_APP_NO_SERVER === '1') {
            await context.dispatch('randomWeigthedSample');
          }
          // Store to server
          const survey = context.rootGetters['survey/getSelected'];
          if (survey !== null) {
            try {
              await api.setWeightOn(survey.id, variables, context.rootState.user.token);
            } catch (e: any) {
              // Restore to old value - hopefully there are not multiple failing toggle requests.
              row.weightOn = oldValue;
              context.commit('setVariables', variables);

              if (e.response !== undefined) {
                reject(e.response.status);
              } else {
                reject();
              }
              return;
            }
          }
        }
        resolve();
      });
    },
    /**
     * Set target population of one or more variable classes.
     * @param context
     * @param payload List of variable+class to set target poplation for
     */
    setTargetPopulation(context:ActionContext<SampleStateInterface, RootState>,
      payload: SetTargetPopulationParams[]) {
      return new Promise<void>(async (resolve, reject) => {
        // ---
        let rejected = false;
        payload.forEach(async (clsParams : SetTargetPopulationParams) => {
          const variables = [...context.state.variables];
          const row = variables.find((r : SampleVariable) => r.id === clsParams.variableId);
          if (row !== undefined) {
            const cls = row.classes.find((c : SampleClass) => c.id === clsParams.classId);
            if (cls !== undefined) {
              const oldValue = cls.targetPopulation;
              cls.targetPopulation = clsParams.targetPopulation;
              context.commit('setVariables', variables);
              // When there is no server => assign random weights
              if (process.env.VUE_APP_NO_SERVER === '1') {
                await context.dispatch('randomWeigthedSample');
              }
              // Store to server
              const survey = context.rootGetters['survey/getSelected'];
              if (survey !== null) {
                try {
                  await api.setTargetPopulation(survey.id, variables, context.rootState.user.token);
                } catch (e: any) {
                  // Restore to old value - hopefully there are not multiple failing requests
                  cls.targetPopulation = oldValue;
                  context.commit('setVariables', variables);

                  if (e.response !== undefined) {
                    reject(e.response.status);
                  } else {
                    reject();
                  }
                  rejected = true;
                }
              }
            }
          }
        });
        if (!rejected) {
          resolve();
        }
      });
    },
    addVariable(context:ActionContext<SampleStateInterface, RootState>,
      payload: SampleVariable) {
      return new Promise<void>(async (resolve, reject) => {
        let rejected = false;
        const oldVariables = [...context.state.variables];
        const variables = [...context.state.variables];
        variables.push(payload);
        context.commit('setVariables', variables);
        const survey = context.rootGetters['survey/getSelected'];
        if (survey !== null) {
          try {
            await api.createVariables(survey.id, [
              payload,
            ], context.rootState.user.token);
            await context.dispatch('load'); // obtain id
          } catch (e: any) {
            if (e.response !== undefined && e.response.status === 401) {
              console.log('401 in response => logout');
              context.dispatch('user/logout', {}, { root: true });
            }
            // Restore to old value - hopefully there are not multiple failing requests
            context.commit('setVariables', oldVariables);

            if (e.response !== undefined) {
              reject(e.response.status);
            } else {
              reject();
            }
            rejected = true;
          }
        }
        if (!rejected) {
          resolve();
        }
      });
    },
    updateVariable(context:ActionContext<SampleStateInterface, RootState>,
      payload: SampleVariable) {
      return new Promise<void>(async (resolve, reject) => {
        let rejected = false;
        const oldVariables = [...context.state.variables];
        const variables = [...context.state.variables];
        const rowIndex = variables.findIndex((r : SampleVariable) => r.id === payload.id);
        if (rowIndex === -1) {
          reject();
          return;
        }
        variables[rowIndex] = payload;
        context.commit('setVariables', variables);
        const survey = context.rootGetters['survey/getSelected'];
        if (survey !== null) {
          try {
            await api.updateVariables(survey.id, [
              payload,
            ], context.rootState.user.token);
            await context.dispatch('load'); // obtain ids in case classes was added
          } catch (e: any) {
            if (e.response !== undefined && e.response.status === 401) {
              console.log('401 in response => logout');
              context.dispatch('user/logout', {}, { root: true });
            }
            // Restore to old value - hopefully there are not multiple failing requests
            context.commit('setVariables', oldVariables);

            if (e.response !== undefined) {
              reject(e.response.status);
            } else {
              reject();
            }
            rejected = true;
          }
        }
        if (!rejected) {
          resolve();
        }
      });
    },
    deleteVariable(context:ActionContext<SampleStateInterface, RootState>,
      variableId: number) {
      return new Promise<void>(async (resolve, reject) => {
        let rejected = false;
        const oldVariables = [...context.state.variables];
        const variables = [...context.state.variables];
        const rowIndex = variables.findIndex((r : SampleVariable) => r.id === variableId);
        if (rowIndex === -1) {
          reject();
          return;
        }
        variables.splice(rowIndex, 1);
        context.commit('setVariables', variables);
        const survey = context.rootGetters['survey/getSelected'];
        if (survey !== null) {
          try {
            await api.deleteVariables(survey.id, [
              variableId,
            ], context.rootState.user.token);
          } catch (e: any) {
            if (e.response !== undefined && e.response.status === 401) {
              console.log('401 in response => logout');
              context.dispatch('user/logout', {}, { root: true });
            }
            // Restore to old value - hopefully there are not multiple failing requests
            context.commit('setVariables', oldVariables);

            if (e.response !== undefined) {
              reject(e.response.status);
            } else {
              reject();
            }
            rejected = true;
          }
        }
        if (!rejected) {
          resolve();
        }
      });
    },
    /* eslint-disable */
    load(context:ActionContext<SampleStateInterface, RootState>) {
      return new Promise<void>(async (resolve, reject) => {
        // Don't clear before load because Weighting view will
        // perform load to update the weighted sample column.

        // but if we did load with an error but there is no data,
        // reset loadError => show spinner when trying again to load.
        if (context.state.variables.length === 0) {
          context.commit('setLoadError', false);
        }

        let variables : Array<SampleVariable> = [];

        if (process.env.VUE_APP_NO_SERVER === '1') {
          console.log('Simulate waiting on server');
          await timeout(1000);
          variables = loadOfflineSampleVariables();
        } else {
          const survey = context.rootGetters['survey/getSelected'];
          if (survey !== null) {
            try {
              const response = await api.getSampleVariables(
                survey.id, context.rootState.user.token,
              );
              const s2 = context.rootGetters['survey/getSelected'];
              if (s2 === null || s2.id !== survey.id) {
                console.log('User changed survey while loading sample data => abort')
                reject();
                return;
              }
              variables = response.data;
            } catch (e: any) {
              if (e.response !== undefined && e.response.status === 401) {
                console.log('401 in response => logout');
                context.dispatch('user/logout', {}, { root: true });
              }
              context.commit('setLoadError', true);
              reject();
              return;
            }
          } else {
            console.error('sampleVariable/load: survey/getSelected is null');
            context.commit('setLoadError', true);
            reject();
            return;
          }
        }

        // Resolve label strings
        for (let i = 0; i < variables.length; i += 1) {
          variables[i].label = resolveLabel(variables[i].label, variables[i].labelTranslations);
          for (let c = 0; c < variables[i].classes.length; c += 1) {
            variables[i].classes[c].label = resolveLabel(
              variables[i].classes[c].label, variables[i].classes[c].labelTranslations,
            );
          }
        }

        // Get expanded from localStorage
        for (let i = 0; i < variables.length; i += 1) {
          const storeKey = getVariableExpandedStorageKey(variables[i]);
          const storedValue = window.localStorage.getItem(storeKey);
          if (storedValue === null) {
            variables[i].isExpanded = true;
            window.localStorage.setItem(storeKey, JSON.stringify(true));
          } else {
            // bias towards expanded in case of type error
            variables[i].isExpanded = JSON.parse(storedValue) !== false;
          }
        }

        context.commit('setVariables', variables);
        if (process.env.VUE_APP_NO_SERVER === '1') {
          await context.dispatch('randomWeigthedSample');
        }
        resolve();
      });
    },
    // When there is no server, this method can be used to assign
    // some random weightedSample values.
    randomWeigthedSample(context:ActionContext<SampleStateInterface, RootState>) {
      const variables = [...context.state.variables];
      let allOk = true;
      for (let i = 0; i < variables.length; i += 1) {
        allOk = allOk && (!variables[i].weightOn
          || context.getters.isTargetPopulationSum1(variables[i].id));
      }
      for (let i = 0; i < variables.length; i += 1) {
        const variable = variables[i];
        const assignWeight = variable.weightOn && allOk;
        for (let c = 0; c < variable.classes.length; c += 1) {
          const cls = variable.classes[c];
          cls.weightedSample = assignWeight ? Math.random() : null;
        }
      }

      context.commit('setVariables', variables);
    },
  },
  getters: {
    /**
     * Checks if targetPopulation sums to 100% for given variable.
     * @param variableId Id of variable to check */
    isTargetPopulationSum1: (state:SampleStateInterface) => (variableId : number) : boolean => {
      const variable = state.variables.find((r : SampleVariable) => r.id === variableId);
      if (variable === undefined) {
        return false; // variable not found
      }

      let sum = 0;
      for (let i = 0; i < variable.classes.length; i += 1) {
        const c = variable.classes[i];
        if (c.targetPopulation !== null) {
          sum += c.targetPopulation;
        }
      }

      const sum1 = Math.abs(1.0 - sum) < 0.001;
      if (!sum1 && variable.isShare && variable.weightOn) {
        console.log(
          `total poplation sum for variable ${resolveLabel(variable.label, variable.labelTranslations)} is not 1. It is ${sum}`
        );
      }
      return sum1;
    },
    /**
     * Checks if variables have been loaded.
     *
     * It makes an assumption that any loaded state have at
     * least one variable. This is safe as there is one variable
     * that count number of participants and should exist for
     * all surveys.
     */
    isLoaded: (state:SampleStateInterface) => state.variables.length > 0,
    isLoadError: (state:SampleStateInterface) => state.loadError,
  },
};
